import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop, Watch } from 'vue-property-decorator';
import Guid from '@/shared/helpers/guid-helper';
import { User, CreateOrUpdateUserInput } from '../../shared/models/dto/users';
import { NameValue } from '../../shared/models/dto/general';
import { Role } from '../../shared/models/dto/roles';

@Component({
    components: {
        errorMessage: require('@/views/_components/error-message/error-message.vue').default
    }
})
export default class EditCreateUserDialogComponent extends AuthComponentBase {
    @Prop({ default: null }) readonly id: string;
    @Prop({ default: false }) readonly show: boolean;

    refs = this.$refs as any;
    loading: boolean = true;
    valid: boolean = true;
    isEdit: boolean = false;
    formTitle: string = '';
    allRoles: Role[] = [];
    errors: NameValue[] = [];

    createOrUpdateInput: CreateOrUpdateUserInput = {
        grantedRoleIds: [],
        user: {} as User,
        password: ''
    };

    passwordRepeat: string = '';

    @Watch('show', { immediate: true })
    async onShowChanged() {
        if (this.show) {
            this.passwordRepeat = '';
            this.loading = true;
            this.isEdit = !!this.id;
            this.formTitle = `${this.isEdit ? this.t('Edit') : this.t('New')} ${this.t('User').toLowerCase()}`;
            this.errors = [];

            this.allRoles = await this.authService.getList('/api/roles');
            if (this.isEdit) {
                await this.authService.get<User>(
                    `/api/users/${this.id}?$expand=UserRoles`
                ).then((response) => {
                    const user: User = response.content;

                    const granted = Object.values(user.userRoles.map(x => x.id));

                    this.createOrUpdateInput = {
                        grantedRoleIds: granted.length ? granted : [],
                        user: user,
                        password: ''
                    };
                });
            }
            this.loading = false;
        }
    }

    save() {
        if (this.refs.form.validate()) {
            this.errors = [];           
            this.createOrUpdateInput.user.companyId = 1;
            this.authService.postOrPut<void>(
                '/api/users',
                this.createOrUpdateInput as CreateOrUpdateUserInput,
                this.createOrUpdateInput.user.id
            ).then((response) => {
                if (!response.isError) {
                    this.swalToast(2000, 'success', this.t('ChangesSaved'));
                    this.$emit('completed', true);
                } else {
                    response.errors.forEach(e => this.errors.push(e));
                }
            });
        }
    }

    close() {
        this.$emit('completed', false);
    }
}