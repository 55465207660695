import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Watch } from 'vue-property-decorator';
import { User } from '../../shared/models/dto/users';
import { FilterOperator } from '../../shared/models/shared/filter-operator';
import { ODataFilterItem, ODataFilterObject } from '../../shared/models/shared/odata-filter-object';
import { IRestResponseDto } from '../../shared/models/shared/rest-response-dto';

@Component({
    components: {
        vDataTableFilter: require('@/views/_components/v-data-table-filter/v-data-table-filter.vue').default,
        editCreateUserDialog: require('@/views/users/edit-create-user-dialog.vue').default
    }
})
export default class UserListComponent extends AuthComponentBase {
    currentRouteName: string = null;
    loading: boolean = true;
    showDialog: boolean = false;
    showFilter: boolean = false;
    id: string = '';

    options = {
        page: 1,
        itemsPerPage: 15,
        sortBy: ['emailaddress']
    };
    totalCount: number = 0;
    items: User[] = [];

    get headers() {
        return [
            { text: this.t('Firstname'), value: 'firstname', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('Lastname'), value: 'lastname', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('Email'), value: 'emailaddress', filterOperator: FilterOperator.contains, inputType: 'string', },
            { text: this.t('Actions'), value: 'action', sortable: false, width: '160px', }
        ];
    }

    @Watch('options', { deep: true, immediate: false })
    onPaginationChanged() {
        this.getItems(); // this also does first load
    }

    @Watch('$route', { deep: true, immediate: true })
    onUrlChange(newVal: any) {
        // if the route contains a param, this watcher makes sure the detailpage is opened
        this.currentRouteName = !this.currentRouteName ? newVal.name : this.currentRouteName;

        if (newVal && newVal.params && newVal.params.id) {
            this.edit(newVal.params.id);
        } else { 
            this.showDialog = false;
        }
    }

    getItems(filterItems?: ODataFilterItem[]) { // when called from v-data-table-filter, it provides the items
        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage }: any = this.options;
        const queryObj: ODataFilterObject = {
            filter: filterItems && filterItems.length ? filterItems : [],
            pageIndex: page - 1,
            pageSize: itemsPerPage,
            $count: true
        };

        if (sortBy.length > 0 && sortBy[0]) {
            queryObj.sortBy = sortBy + ((sortDesc && sortDesc.length > 0 && sortDesc[0]) ? ' desc' : '');
        }

        const query = this.queryBuilder(queryObj);
        this.authService.get<IRestResponseDto<User[]>>(`/api/users/${query}&companyId=${this.authStore.getUser().companyId}`, false).then((response) => {
            this.items = <User[]><unknown>response.content;
            this.totalCount = response.count;
            this.loading = false;
        }).catch((error) => {
            this.totalCount = 0;
            this.loading = false;
        });
    }

    deleteItem(id: string) {
        this.swalConfirm(this.t('AreYouSureToDelete'), true, this.t('Delete')).then((result) => {
            if (result.value) {
                this.authService.delete(`/api/users/${id}`).then((response) => {
                    if (!response.isError) {
                        this.swalToast(2000, 'success', this.t('ChangesSaved'));
                        this.getItems();
                    } else {
                        var errorText = "";
                        response.errors.forEach((error: { name: string; }) => {
                            errorText += this.t(error.name) + '<br>';
                        });
                        this.swalToast(3000, 'error', errorText);
                    }
                });
            }
        });
    }

    edit(id: string) {
        this.id = id ? id : '';

        if (id) {
            this.$router.push({ name: this.currentRouteName, params: { id: this.id } });
        }

        this.showDialog = true;
    }

    completed(reload: boolean) {
        this.$router.push({ name: this.currentRouteName });
        this.showDialog = false;
        if (reload) {
            this.getItems();
        }
    }
}